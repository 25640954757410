@import "variables";
@import "tabler";
@import "components/index";
@import "vendor";

$bg-primary: rgb(0, 49, 166);
$bg-secondary: rgb(98, 129, 187);

$bg-primary-alt: rgb(96, 168, 69);
$bg-secondary-alt: rgb(171, 248, 210);

body.user .bg-image {
  background-image: url("../images/bg.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

body.admin .bg-image {
  background-color: transparent !important;
  background-image: url("../images/bg.png") !important;
  background-position: 50%;
  background-repeat: repeat;
  background-size: 500px;
  animation: scrollingBgReveal 80s linear infinite;
}

@keyframes scrollingBgReveal {
  0% {
    background-position-y: 0;
  }

  to {
    background-position-y: -2000px;
  }
}
