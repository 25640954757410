.carousel-thumbnails {
  .carousel-indicators {
    left: initial;
    margin-left: initial;
    position: static;
    width: initial;

    .active img {
      border: 1px solid black;
      opacity: 0.7;
    }
  }
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: black;
  border-radius: 50%;
  opacity: 0.8;
}
