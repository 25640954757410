.blurry-text {
  color: transparent;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.blurry-text:hover {
  color: inherit;
  text-shadow: initial;
}

.hr-text:after, .hr-text:before {
  flex: 1 1 auto;
  height: 1px;
  background-color: currentColor;
  opacity: .1;
}

.hr-text:after {
  content: "";
  margin-left: 0.5rem;
}
