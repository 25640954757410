.tox-tinymce {
  font-family: inherit !important;
  border: 1px solid var(--tblr-border-color) !important;
  border-radius: var(--tblr-border-radius) !important;
}

.theme-dark {
  .tox .tox-statusbar,
  .tox:not(.tox-tinymce-inline) .tox-editor-header,
  .tox .tox-menubar,
  .tox .tox-toolbar-overlord,
  .tox .tox-toolbar,
  .tox .tox-toolbar__overflow,
  .tox .tox-toolbar__primary {
    background-color: var(--tblr-dark) !important;
  }
}
