@import "datatables.net-bs5/css/dataTables.bootstrap5";
@import "datatables.net-responsive-bs5/css/responsive.bootstrap5";
@import "datatables.net-buttons-bs5/css/buttons.bootstrap5";
@import "datatables.net-scroller-bs5/css/scroller.bootstrap5";
@import "datatables.net-rowreorder-bs5/css/rowReorder.bootstrap5";
@import "datatables.net-fixedheader-bs5/css/fixedHeader.bootstrap5";
@import "datatables.net-fixedcolumns-bs5/css/fixedColumns.bootstrap5";
@import "datatables.net-rowgroup-bs5/css/rowGroup.bootstrap5";
//@import "datatables.net-select-bs5/css/select.bootstrap5";

table {
  --tblr-table-bg: var(--tblr-bg-surface) !important;
}

.dataTables_wrapper {
  .card-footer {
    background-color: var(--tblr-bg-surface) !important;
    border-top: 0 !important;
  }
  .page-item {
    text-transform: capitalize;
  }
  .page-item + .page-item {
    margin-left: 0.25rem;
  }
}

table.table {
  font-size: 13px;
  th,
  td {
    vertical-align: middle;
  }
}

table.dataTable {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}


.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.45rem;
}

.card-datatable {
  margin-bottom: 0 !important;
}

table.dataTable {
  &.nowrap,
  &.no-wrap {
    th.wrap,
    td.wrap {
      white-space: normal;
    }

    th.pre,
    td.pre {
      white-space: pre;
    }
  }

  span.dtr-title {
    min-width: 150px !important;
    vertical-align: top !important;
  }

  span.dtr-data {
    display: inline-block;
    white-space: normal;
    width: 85%;
  }

  @include media-breakpoint-up(md) {
    td.ellipsis,
    th.ellipsis {
      max-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.dt-button-collection.drop {
  left: auto !important;
  right: 0;

  .dropdown-menu {
    @extend .dropdown-menu-end;
  }

  .dropdown-item {
    height: 35px;
    padding: 6px;
    margin-left: 0 !important;
    font-size: 14px;
    min-width: 5rem;
  }
}

.theme-dark div.dt-button-collection {
  --tblr-card-bg: #1e293b;
  background-color: var(--tblr-card-bg);
}

table.table-processing {
  min-height: 100px;
}

.btn-dttb .btn.disabled {
  display: none !important;
}

table.table .status {
  font-size: 0.75rem !important;
}

.card-body > .card.dataTables_wrapper  {
  border: 0 !important;
}
