.btn-block {
  width: 100%;
  display: block;
}

.btn.link-icon {
  padding: 2px;
  line-height: 0;
}

:not(a)[data-action^="click"] {
  cursor: pointer;
}

:not(a)[data-action^="click"]:hover {
  @extend .bg-blue-lt;
}

.btn .icon {
  --tblr-btn-icon-size: 1rem;
}
