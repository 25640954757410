.hidden {
  display: none;
}

.no-shadow {
  box-shadow: none;

  &:focus {
    box-shadow: none;
  }
}

.no-caret::after,
.no-caret a:after {
  display: none;
}

.overflow-initial {
  overflow: initial !important;
}

.clipboard--supported a.copy {
  display: none;
  cursor: pointer;
}

.clipboard--supported:hover a.copy {
  color: #a0522d;
  display: inline;
}

.clipboard--supported .icon {
  font-size: 14px;
}

.fill-screen {
  min-height: calc(100vh - 16rem);
}

.fill-screen-max {
  max-height: calc(100vh - 16rem);
}
