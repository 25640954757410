@import url('https://rsms.me/inter/inter.css');
// $font-google: null !default;
// $font-google-monospaced: null !default;
$font-local: 'Inter';

// $theme-colors: (
//   "primary": green
// );
:root {
  --svg-bg-primary: #0186C2;
  --svg-bg-secondary: #F15B1F;
}


[data-bs-theme=light] {
  --tblr-white: rgba(255, 255, 255, 0.5) !important;
  --tblr-bg-surface-tertiary: var(--tblr-white) !important;

  .dropdown-menu {
    --tblr-dropdown-bg: rgba(255, 255, 255, 1) !important;
  }

  .modal {
    --tblr-modal-bg: rgba(255, 255, 255, 1) !important;
  }

  .card {
    --tblr-card-bg: hsla(0, 0%, 100%, 0.8) !important;
  }
}

[data-bs-theme=dark] {
  --tblr-dark: rgba(24, 36, 51, 0.8) !important;
  --tblr-bg-surface: var(--tblr-dark) !important;
  --tblr-bg-surface-tertiary: var(-tblr-bg-surface) !important;

  .dropdown-menu {
    --tblr-dropdown-bg: rgba(24, 36, 51, 1) !important;
  }

  .modal {
    --tblr-modal-bg: rgba(24, 36, 51, 1) !important;
  }
}

.modal-backdrop {
  --tblr-backdrop-opacity: 0.8 !important;
}

@media (min-width: 992px) {
  .navbar-vertical.navbar-expand-lg .navbar-collapse .nav-item.active:after {
    border-left-width: 5px !important;
    top: 7px !important;
    height: 23px;
    border-radius: 10px;
  }
}
