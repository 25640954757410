@import "@selectize/selectize/dist/css/selectize.bootstrap5";

.selectize-control {
  .dropdown-menu {
    width: 100%;
    height: auto;
  }
}

.selectize-control {
  padding: 0;
  border: 0;
  height: auto;
}

.selectize-control > .selectize-input {
  @extend .form-select
}

.selectize-input::after {
  display: none !important;
}

.selectize-input, .selectize-control.single .selectize-input.input-active {
  background: inherit;
  background-color: inherit;
}

.selectize-control.form-control.form-select:not(select):read-only {
  background: inherit;
  background-color: inherit;
}

.selectize-dropdown .option {
  @extend .dropdown-item;
  padding: var(--tblr-dropdown-item-padding-y) var(--tblr-dropdown-item-padding-x);
  color: var(--tblr-dropdown-link-color);
}
