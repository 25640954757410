html {
  scroll-behavior: auto !important;
}

.card {
  --tblr-card-cap-padding-y: 1rem !important;
}

.card+.card {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.page {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  background-image: url('../images/beam.svg');
}

.page-bg {
  --bd-pink-rgb: 214, 51, 132;
  --bs-body-bg-rgb: 255, 255, 255;
  --bd-accent-rgb: 255, 193, 7;
  --bd-violet-rgb: 108, 117, 125;
  --bs-primary-rgb: 0, 123, 255;
  background-image: linear-gradient(180deg, rgba(var(--bs-body-bg-rgb), 0.01), rgba(var(--bs-body-bg-rgb), 1) 85%),
    radial-gradient(ellipse at top left, rgba(var(--bs-primary-rgb), 0.5), transparent 50%),
    radial-gradient(ellipse at top right, rgba(var(--bd-accent-rgb), 0.5), transparent 50%),
    radial-gradient(ellipse at center right, rgba(var(--bd-violet-rgb), 0.5), transparent 50%),
    radial-gradient(ellipse at center left, rgba(var(--bd-pink-rgb), 0.5), transparent 50%);
}

@media (min-width: 992px) {
  .navbar-expand-lg .nav-item.active:after {
    box-shadow: 0 0 40px 10px var(--tblr-info);
  }
}

.page.sudo-mode {
  padding-bottom: 15px;
}

.navbar-scrolled {
  background-color: var(--tblr-bg-surface) !important;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}
