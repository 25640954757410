abbr[title] {
  text-decoration: none;
}

// apply primary button style to buttons with only `btn` class
[class="btn"] {
  @extend .btn-primary;
}

// optional vertical & horizontal form spacing
.form-check-inline {
  .form-check-label {
    &.collection_check_boxes,
    &.collection_radio_buttons {
      margin-right: 0.265625rem;
    }
  }
}

// In bootstrap 5 legend floats left and requires the following element
// to be cleared. In a radio button or checkbox group the element after
// the legend will be the automatically generated hidden input; the fix
// in https://github.com/twbs/bootstrap/pull/30345 applies to the hidden
// input and has no visual effect. Here we try to fix matters by
// applying the clear to the div wrapping the first following radio button
// or checkbox.
legend ~ div.form-check:first-of-type {
  clear: left;
}

.form-select {
  &.date,
  &.datetime,
  &.time {
    &:first-of-type {
      margin-left: 0 !important;
    }
    &:last-of-type {
      margin-right: 0 !important;
    }
  }
}
