.form-row {
  @extend .row;
}

.input-icon-addon {
  max-height: $input-height;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group-valid .input-group-text {
  border-color: #5eba00;
}

.form-group-invalid .input-group-text {
  border-color: #fa4654;
}

.readonly .input-group-text {
  background-color: #f5f7fb !important;
  opacity: 1;
  border-right: 0;
}

.form-control:not(select).readonly,
.form-control:not(select):read-only {
  @extend :disabled
}

.form-control:disabled, .form-control:not(select).readonly, .form-control:not(select):read-only, .selectize-input.disabled {
  cursor: not-allowed;
}

input.form-control[type="hidden"]+.form-control {
  margin-left: 0;
}

.form-control-plaintext:focus {
  outline: none;
}

.form-row>div,
.form-row .form-group[class*="col-"] {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.form-control-static {
  box-shadow: 0 -1px 0 #ddd inset;
  height: 33px;
}

.show_form .form-control {
  cursor: default;
}

.form-control {
  &.form-control--focus {
    border-color: #7aade9;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(32, 107, 196, 0.25);
  }
}

input.form-control.datepicker {
  margin-left: 0rem !important;
}

.input-group-flat .input-group-text {
  background: #ffffff;
}

a.icon-toggle {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
}

.form-group.hidden {
  margin-bottom: 0;
}

.is-invalid .invalid-feedback {
  display: block;
}

label.form-switch:has(> input.d-none) {
  display: none;
}
